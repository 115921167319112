import Vue from "vue";

Vue.filter("minutesToHours", function (minutes) {
  if (!minutes) return "";

  const sign = minutes < 0 ? "- " : ""; // Determine the sign
  minutes = Math.abs(minutes); // Make minutes positive
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return sign + `${hours}:${String(minutes).padStart(2, "0")}`;
});
