var render = function () {
  var _vm$user, _vm$attendanceDetails, _vm$attendanceDetails2, _vm$attendanceDetails3, _vm$attendanceDetails4, _vm$attendanceDetails5, _vm$attendanceDetails6, _vm$attendanceDetails7, _vm$attendanceDetails8, _vm$attendanceDetails9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v("Details")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Name: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" First Check In: "), (_vm$attendanceDetails = _vm.attendanceDetails) !== null && _vm$attendanceDetails !== void 0 && _vm$attendanceDetails.first_check_in ? _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(" " + _vm._s(_vm._f("moment")([(_vm$attendanceDetails2 = _vm.attendanceDetails) === null || _vm$attendanceDetails2 === void 0 ? void 0 : _vm$attendanceDetails2.first_check_in, "HH:mm:ss"], "LT")) + " ")]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Last Check Out: "), (_vm$attendanceDetails3 = _vm.attendanceDetails) !== null && _vm$attendanceDetails3 !== void 0 && _vm$attendanceDetails3.last_check_out ? _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm._f("moment")([(_vm$attendanceDetails4 = _vm.attendanceDetails) === null || _vm$attendanceDetails4 === void 0 ? void 0 : _vm$attendanceDetails4.last_check_out, "HH:mm:ss"], "LT")))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Expected Duty Hours: "), (_vm$attendanceDetails5 = _vm.attendanceDetails) !== null && _vm$attendanceDetails5 !== void 0 && _vm$attendanceDetails5.expected_duty_minutes ? _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm._f("minutesToHours")((_vm$attendanceDetails6 = _vm.attendanceDetails) === null || _vm$attendanceDetails6 === void 0 ? void 0 : _vm$attendanceDetails6.expected_duty_minutes)))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base "
  }, [_vm._v(" Total Worked Hours: "), (_vm$attendanceDetails7 = _vm.attendanceDetails) !== null && _vm$attendanceDetails7 !== void 0 && _vm$attendanceDetails7.total_worked_minutes ? _c('span', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formattedTime((_vm$attendanceDetails8 = _vm.attendanceDetails) === null || _vm$attendanceDetails8 === void 0 ? void 0 : _vm$attendanceDetails8.total_worked_minutes)))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Date: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm._f("moment")((_vm$attendanceDetails9 = _vm.attendanceDetails) === null || _vm$attendanceDetails9 === void 0 ? void 0 : _vm$attendanceDetails9.date, "ll")))])])])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "cols": "12"
    }
  }, [_c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: true,
        multipleColumns: true
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10, _props$row11, _props$row12;
        return [props.column.field === 'format_worked_minutes' ? _c('span', [props !== null && props !== void 0 && (_props$row = props.row) !== null && _props$row !== void 0 && _props$row.worked_minutes ? [props !== null && props !== void 0 && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && _props$row2.remarks ? [_c('span', {
          staticClass: "reconciled-time font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formattedTime(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.worked_minutes)) + " ")])] : [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formattedTime(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.worked_minutes)) + " ")])]] : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("N/A")])], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")]), props.column.field === 'format_check_in' ? [props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.check_in ? _c('span', [props !== null && props !== void 0 && (_props$row6 = props.row) !== null && _props$row6 !== void 0 && _props$row6.remarks ? [_c('span', {
          staticClass: "reconciled-time font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.check_in)) + " ")])] : [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.check_in)) + " ")])]], 2) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("N/A")])] : _vm._e(), props.column.field === 'format_check_out' ? [props !== null && props !== void 0 && (_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.check_out ? _c('span', [props !== null && props !== void 0 && (_props$row10 = props.row) !== null && _props$row10 !== void 0 && _props$row10.remarks ? [_c('span', {
          staticClass: "reconciled-time font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn(props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.check_out)) + " ")])] : [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn(props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.check_out)) + " ")])]], 2) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("N/A")])] : _vm._e()];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }