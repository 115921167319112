<template>
  <section>
    <b-row>
      <b-col lg="4" cols="12">
        <b-card>
          <b-card-text>
            <p class="text-uppercase custom-text-disabled">Details</p>
            <b-list-group flush>
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Name: <span class="text-body-2">{{ user?.name }}</span>
              </h6></b-list-group-item
              >
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                First Check In: <span v-if="attendanceDetails?.first_check_in" class="text-body-2"> {{
                  [attendanceDetails?.first_check_in, "HH:mm:ss"]
                      | moment("LT")
                }} </span> <b-badge v-else variant="danger">N/A</b-badge>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Last Check Out: <span v-if="attendanceDetails?.last_check_out" class="text-body-2">{{
                  [attendanceDetails?.last_check_out, "HH:mm:ss"]
                      | moment("LT")
                }}</span> <b-badge v-else variant="danger">N/A</b-badge>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Expected Duty Hours: <span v-if="attendanceDetails?.expected_duty_minutes" class="text-body-2">{{ attendanceDetails?.expected_duty_minutes | minutesToHours }}</span> <b-badge v-else variant="danger">N/A</b-badge>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base ">
                Total Worked Hours: <span v-if="attendanceDetails?.total_worked_minutes" class="text-body-2 font-weight-bold">{{ formattedTime(attendanceDetails?.total_worked_minutes) }}</span> <b-badge v-else variant="danger">N/A</b-badge>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Date: <span class="text-body-2">{{ attendanceDetails?.date | moment("ll") }}</span>
              </h6></b-list-group-item
              >

            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col lg="8" cols="12">
        <b-card>
          <div>
            <!-- table -->
            <vue-good-table
                styleClass="vgt-table table-custom-style striped"
              :line-numbers="false"
              :rows="rows"
              :columns="columns"
              :sort-options="{
                enabled: true,
                multipleColumns: true,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >

              <template slot="table-row" slot-scope="props">
                <!-- format_worked_minutes -->
                <span v-if="props.column.field === 'format_worked_minutes'">
                  <template v-if="props?.row?.worked_minutes">
                      <template v-if="props?.row?.remarks">
                      <span class="reconciled-time font-weight-bold">
                        {{ formattedTime(props?.row?.worked_minutes) }}
                      </span>
                    </template>
                      <template v-else >
                        <span class="font-weight-bold"> {{ formattedTime(props?.row?.worked_minutes) }} </span>
                      </template>
                  </template>
                  <b-badge v-else variant="danger">N/A</b-badge>

                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>



                <!-- Check in -->
                <template v-if="props.column.field === 'format_check_in'">
                  <span v-if="props?.row?.check_in">
                    <template v-if="props?.row?.remarks">
                      <span class="reconciled-time font-weight-bold">
                        {{ formatFnTableCheckIn(props?.row?.check_in) }}
                      </span>
                    </template>

                    <template v-else>
                      <span class="font-weight-bold"> {{ formatFnTableCheckIn(props?.row?.check_in) }} </span>
                    </template>

                  </span>

                  <b-badge v-else variant="danger">N/A</b-badge>
                </template>

                <!-- checkout  -->
                <template v-if="props.column.field === 'format_check_out'">
                  <span v-if="props?.row?.check_out">
                    <template v-if="props?.row?.remarks">
                      <span class="reconciled-time font-weight-bold">
                        {{ formatFnTableCheckIn(props?.row?.check_out) }}
                      </span>
                    </template>

                    <template v-else>
                      <span class="font-weight-bold"> {{ formatFnTableCheckIn(props?.row?.check_out) }} </span>
                    </template>

                  </span>
                  <b-badge v-else variant="danger">N/A</b-badge>
                </template>

                <!-- worked hour -->


              </template>

              <!-- format_check_in -->


              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTimepicker,
  BSpinner, BListGroupItem, BListGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { ATTENDANCE_DETAILS_EDIT } from "@/helpers/permissionsConstant";
import minutesToHours from "@/filter/minutesToHours";
import { EventBus } from "@/helpers/event-bus";

export default {
  name: "AttendanceDetailsView",
  components: {
    BListGroup,
    BListGroupItem,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BFormTimepicker,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ATTENDANCE_DETAILS_EDIT,
      user: null,
      attendanceDetails: null,
      modelType: "",
      attendance_activity_id: "",
      check_in: "",
      check_out: "",
      pageLength: 10,
      columns: [

        {
          label: "Check In",
          field: "format_check_in",
          formatFn: this.formatFnTableCheckIn,
        },
        {
          label: "Check Out",
          field: "format_check_out",
          formatFn: this.formatFnTableCheckOut,
        },
        {
          label: "Total (HH:mm)",
          field: "format_worked_minutes",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "remarks",
          sortable: false,
        },
      ],
      rows: [],
      isAttendanceEditFormSubmitLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    this.loadItems();

    EventBus.$on("changed-check-in-out", () => {
      if (this.$route?.name === "admin-attendance-details") {
        this.loadItems();
      }
    });
  },
  methods: {
    formattedTime(value) {
      if (value == 0) return "";
      return this.$moment
        .utc(value * 60 * 1000) // Convert minutes to milliseconds
        .format("H:mm");
    },
    formatFnTableCheckDate(value) {
      if (value) {
        return this.$moment(value).format("ll");
      }
    },
    formatFnTableCheckIn(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LT");
      }
    },
    formatFnTableCheckOut(value) {
      if (value) {
        return this.$moment(value, "HH:mm:ss").format("LT");
      }
    },

    showModal() {
      this.$bvModal.show("modal-attendance-details-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-attendance-details-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.attendance_id = "";
      this.check_in = "";
      this.check_out = "";
    },
    async onShow(value) {
      this.modelType = "editModel";

      this.attendance_activity_id = value.id;
      this.check_in = value.check_in;
      this.check_out = value.check_out;

      this.showModal();
    },

    async loadItems() {
      try {
        const id = this.$route.params.id;
        const res = await this.$api.get(
          `/api/attendances/${id}?include=user,attendanceActivities`
        );
        const { attendanceActivities, user, ...attendanceDetails } =
          res?.data?.data;

        this.user = user?.data;

        this.rows = attendanceActivities?.data;
        console.log(res?.data?.data);
        this.rows.sort((a, b) => {
          const timeA = new Date(`${a?.date} ${a?.check_in}`);
          const timeB = new Date(`${b?.date} ${b?.check_in}`);

          // Compare and return the result
          return timeA - timeB;
        });

        this.attendanceDetails = attendanceDetails;

        // this.department = res?.data?.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.reconciled-time {
  color: red;
}
</style>
